import { Box, Text, Flex, useTheme } from "@chakra-ui/react";
import QRCode from "react-qr-code";
import { getSessionStorage } from "../../helper";
import ConfirmDetails from "./confirm-details";

const BookSlip = () => {
  const bookingId: string = getSessionStorage("bookingId");
  const meetingId: string = getSessionStorage("meetingId");

  const {
    colors: {
      brand: { main },
    },
  } = useTheme();

  return (
    <Box width="100%" p={{ base: "1rem", md: "2rem" }}>
      <Text fontWeight="700">Booking slip</Text>
      <Box>
        <Flex
          justify="center"
          mt="9.6rem"
          mb="4.7rem"
          fontSize={{ base: "2.0rem", md: "3.2rem" }}
          fontWeight="600"
          gap="1.5rem"
        >
          <Text color={`${main}`}>Booking ID:</Text>
          <Text>{bookingId}</Text>
        </Flex>
        <ConfirmDetails />
        <Flex direction="column" align="center" gap="2.7rem" mt="6.3rem">
          <QRCode size={128} value={meetingId || ""} />
          <Text fontSize="1.2rem">Appointment QR Code</Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default BookSlip;
