import { Flex, Image, Heading, ChakraProvider, Text } from "@chakra-ui/react";
import { ErrorBoundary } from "react-error-boundary";
import { ColorRing } from "react-loader-spinner";

import AppButton from "../components/app-button/app-button";
import ErrorDisplay from "../components/errors";
import { useGlobalBootstrapContext } from "../context/bootstrap";
import Breadcrumb from "./breadcrumb";

export interface PropType {
  children: JSX.Element | JSX.Element[];
  current: number;
  setCurrent: (current: number) => void;
}

const Layout = ({ children, current, setCurrent }: PropType) => {
  let { theme, error, loading, bootstrapData, accessToken } =
    useGlobalBootstrapContext();
  return (
    <ChakraProvider theme={theme}>
      {error || !accessToken ? (
        <ErrorDisplay color={theme.colors.brand.main} />
      ) : loading ? (
        <Flex w="100vw" h="100vh" align="center" justify="center">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </Flex>
      ) : (
        <>
          <Flex
            w="100%"
            pl={{ base: "1.6rem", md: "10.5rem" }}
            h="88px"
            backgroundColor="#F9F9FB"
            lineHeight="88px"
          >
            {bootstrapData &&
              (bootstrapData?.data?.logo ? (
                <Image src={bootstrapData?.data?.logo?.url} alt="logo" />
              ) : (
                <Text fontWeight="700" fontSize="2.5rem">
                  {bootstrapData.data.name || ""}
                </Text>
              ))}
          </Flex>
          <Heading
            fontSize="24px"
            color="rgba(0,0,0, 0.8)"
            textAlign="center"
            mt="24px"
            mb="46px"
          >
            Book Appointment
          </Heading>
          <Breadcrumb current={current} setCurrent={setCurrent} />
          <ErrorBoundary
            fallbackRender={() => (
              <Flex
                justify="center"
                align={"center"}
                flexDir={"column"}
                gap="3.2rem"
              >
                <Flex justify="center" align={"center"} flexDir={"column"}>
                  <Text>An error occurred!</Text>
                  <Text>
                    Please try again or contact your service provider for further
                    details.
                  </Text>
                </Flex>
                <AppButton variant="secondary" onClick={() => window.history.back()}>
                  Take me back
                </AppButton>
              </Flex>
            )}
          >
            {children}
          </ErrorBoundary>
        </>
      )}
    </ChakraProvider>
  );
};

export default Layout;
