import { AxiosError } from "axios";
import { createContext, useContext } from "react";
import { BootstrapDataType } from "./state";

export interface BootstrapContextInterface {
  loading: boolean;
  accessToken: string | undefined;
  bootstrapData: BootstrapDataType;
  error: AxiosError;
  theme: any;
}

const BootstrapContext = createContext<BootstrapContextInterface | null>(null);

export const useGlobalBootstrapContext = () => {
  return useContext(BootstrapContext) as BootstrapContextInterface;
};

export default BootstrapContext;
