import { Box, Flex, Image, Spacer, Text, useTheme } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { DoctorType, PreferredDoctorDateAndTimeType } from "./date-time";

const images = {
  doctor: "/assets/images/doctor.png",
};

interface DoctorProps {
  doctor: DoctorType;
  setPreferredDoctorDateAndTime: (
    bookingDetails: PreferredDoctorDateAndTimeType
  ) => void;
  preferredDoctorDateAndTime: PreferredDoctorDateAndTimeType;
}

const Doctor: FunctionComponent<DoctorProps> = ({
  doctor,
  preferredDoctorDateAndTime,
  setPreferredDoctorDateAndTime,
}) => {
  const theme = useTheme();

  const nonActive = {
    backgroundColor: "#f9f9fb",
  };

  const active = {
    background: "#fff",
    borderColor: theme.colors.brand.main,
    borderWidth: "3px",
    borderStyle: "solid",
  };
  return (
    <Flex
      key={doctor.id}
      maxW={{ base: "35rem", md: "44rem" }}
      mx={{ base: "auto", md: "unset" }}
      style={
        doctor.id === preferredDoctorDateAndTime.preferredDoctor.id
          ? active
          : nonActive
      }
      boxShadow="7.19854px 7.19854px 28.7942px rgba(186, 186, 186, 0.075)"
      borderRadius="8px"
      cursor="pointer"
      align="center"
      padding="1rem 2rem"
      onClick={() =>
        setPreferredDoctorDateAndTime({
          ...preferredDoctorDateAndTime,
          preferredDoctor: doctor,
        })
      }
      mb="2rem"
    >
      <Image
        mr="1rem"
        width="4rem"
        className="image"
        src={images.doctor}
        alt="doctor"
      />
      <Box>
        <Text className="name" fontWeight="700" fontSize="1.6rem">
          {doctor.name}
        </Text>
        <Text className="role" fontSize="1.4rem">
          {doctor.role}
        </Text>
      </Box>
      {preferredDoctorDateAndTime.preferredDoctor.id === doctor.id && (
        <>
          <Spacer />
          <Box
            height="1.6rem"
            width="1.6rem"
            background={`${theme.colors.brand.main}`}
            borderRadius="50%"
            ml="1rem"
          />
        </>
      )}
    </Flex>
  );
};

export default Doctor;
