export const ConsultationIcons: { [key: string]: string } = {
  Coronavirus: "./assets/icons/corona.svg",
  "Ear, Nose, Throat": "Ear, Nose, Throat",
  Pediatrics: "./assets/icons/pediatrics.svg",
  "General Physician": "./assets/icons/genphy.svg",
  Cardiology: "./assets/icons/cardio.svg",
  Psychiatry: "./assets/icons/psych.svg",
  Neurology: "./assets/icons/neuro.svg",
  "Stomach and digestion": "./assets/icons/sandd.svg",
  Dermatology: "./assets/icons/derma.svg",
};
