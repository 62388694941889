import axios from "axios";
import moment from "moment";

export function getSessionStorage(key: string) {
  let storedValue = sessionStorage.getItem(key);
  if (!storedValue) {
    if (key === "current") {
      return 0;
    }
    return "";
  }
  return JSON.parse(storedValue);
}

export function setItem(key: string, data: any): void {
  sessionStorage.setItem(`${key}`, JSON.stringify(data));
}

export const formatTime = (time: string): string => {
  const [hour, minutes] = time.split(":");
  const format = Number(hour) >= 12 ? "PM" : "AM";
  const displayedHour = Number(hour) <= 12 ? hour : Number(hour) - 12;
  return `${displayedHour}:${minutes} ${format}`;
};

export const customSwrFetcher = async ({ url }: { url: string }) => {
  const response = await axios.get(url);
  return response.data;
};

export const reformData = (data: any): any[] =>
  data?.pageEdges?.map((d: any) => ({ ...d?.node }));

export const toEnglishDateFormat = (date: Date) =>
  encodeURIComponent(moment(date).toISOString(true));
