import { Text, Grid, Flex, useTheme, Tooltip } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { ConsultationIcons } from "./consultationUtils";
import { ConsultationTypes, SelectedAreaType } from "./options";
import { useState } from "react";

type PropType = {
  selectedArea: SelectedAreaType;
  setSelectedArea: (value: SelectedAreaType) => void;
  consultations: ConsultationTypes;
};

const Consultations = ({
  selectedArea,
  setSelectedArea,
  consultations,
}: PropType) => {
  const {
    colors: {
      brand: { main, mainRGB },
    },
  } = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {(consultations ?? []).length < 0 ? (
        <Flex align="center">
          <Text>
            There are currently no services available. Please, contact your service
            provider for further details.
          </Text>
        </Flex>
      ) : (
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" }}
          gap="3.6rem"
          width="100%"
          px={{ base: "1.6rem", md: "6.4rem" }}
          mb="3.2rem"
        >
          {(consultations ?? []).map((type) => {
            const { id, name, photo, description, alias } = type;

            // This checks for the icon type that is tied
            // to a specific consultation type in the local repository
            // which can be found in the consultationTypes file
            const icon =
              ConsultationIcons[
                Object.keys(ConsultationIcons).find(
                  (_type) => name.toLowerCase() === _type.toLowerCase()
                ) || ""
              ];

            return (
              <Flex
                key={id}
                align="center"
                justifyContent="space-between"
                gap="2.4rem"
                _hover={{
                  backgroundColor: `rgba(${mainRGB}, 0.12)`,
                }}
                cursor="pointer"
                p="0.3rem"
                borderRadius="8px"
                onClick={() => setSelectedArea({ id, name })}
                border={
                  selectedArea.id === id ? `3px solid ${main}` : "3px solid #fff"
                }
              >
                <Flex alignItems="center" justifyContent="center" gap="1.5rem">
                  <SVG
                    // If a photo is received from the server, it gets rendered.
                    //Otherwise, an icon that suits the consultation type,
                    //if available in the consultation file, will be rendered.
                    src={photo || icon}
                    description={name}
                    preProcessor={(code) =>
                      code.replace(/fill="#0EAA98"/g, `fill="${main}"`)
                    }
                  >
                    <MdOutlineHealthAndSafety color={main} size={"4rem"} />
                  </SVG>

                  <Text textTransform={"capitalize"}>{alias || name}</Text>
                  <Tooltip
                    label={description}
                    placement="top"
                    bg="#fff"
                    hasArrow
                    color="#212121"
                    fontSize="12px"
                    fontWeight="600"
                    textAlign="center"
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                  >
                    <Flex>
                      {description && (
                        <SVG
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsOpen(!isOpen);
                          }}
                          src="/assets/icons/help.svg"
                        />
                      )}
                    </Flex>
                  </Tooltip>
                </Flex>
                {/* {selectedArea.id === id && (
                      <Box
                        w="1.6rem"
                        h="1.6rem"
                        mr="2.4rem"
                        backgroundColor={`${main}`}
                        borderRadius="50%"
                        border={`3px solid rgba(${mainRGB}, 0.12)`}
                      ></Box>
                    )} */}
              </Flex>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default Consultations;
