import { useGlobalBootstrapContext } from "../context/bootstrap";
import axios from "axios";
import { useState, useEffect } from "react";

export const useGetServices = (params: { cursor?: string }) => {
  const { accessToken } = useGlobalBootstrapContext();
  const url = `${process.env.REACT_APP_API_ENDPOINT_URL}departments/specialties?access_token=${accessToken}`;
  const [response, setResponse] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetcher = async () => {
      setIsLoading(true);
      try {
        const res = await getServices(url, params);
        setResponse(res?.data?.data);
      } catch (err) {
        setError(err);
      }
      setIsLoading(false);
    };

    fetcher();
  }, [params, url]);

  return {
    response,
    isLoading,
    error,
  };
};

const getServices = (url: string, params: { cursor?: string }) =>
  axios({
    method: "get",
    url,
    params: params || {},
  });
