import { useState, useEffect, useLayoutEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import "./App.css";

import Confirm from "./appointments/confirm";
import Consultation from "./appointments/consultation";
import DateTime from "./appointments/date-time/date-time";
import DetailsForm from "./appointments/details-form/details-form";
import Layout from "./appointments/layout";
import { getSessionStorage } from "./helper/index";
import BootstrapProvider from "./context/bootstrap/state";
import { CurrentSteps } from "./appointments/breadcrumb";
import Home from "./components/home";
import theme from "./theme/theme";

export interface AppointmentType {
  area: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  date: string;
  time: string;
  meetingWith: string;
  reason: string;
  details?: string;
}

const Pages: {
  [key: string]: (props: {
    current: number;
    setCurrent: (current: number) => void;
  }) => JSX.Element;
} = {
  [String(CurrentSteps.FirstStep)]: Consultation,
  [String(CurrentSteps.SecondStep)]: DateTime,
  [String(CurrentSteps.ThirdStep)]: DetailsForm,
  [String(CurrentSteps.FourthStep)]: Confirm,
};

function App() {
  const bookingId: string = getSessionStorage("bookingId");
  const meetingId: string = getSessionStorage("meetingId");

  const [current, setCurrent] = useState<number>(
    Number(getSessionStorage("current"))
  );

  const CurrentPage = Pages[String(current)];

  useLayoutEffect(() => {
    sessionStorage.clear();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.serviceId && params?.name) {
      const selectedArea = { id: params.serviceId, name: params.name };
      sessionStorage.setItem("areaOfConsultation", JSON.stringify(selectedArea));
      setCurrent(1);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("current", JSON.stringify(current));
    window.scrollTo(0, 0);
  }, [current]);

  useEffect(() => {
    if (bookingId && meetingId) {
      sessionStorage.clear();
      setCurrent(0);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ChakraProvider theme={theme}>
              <Home />
            </ChakraProvider>
          }
        ></Route>
        <Route
          path="/:accesstoken"
          element={
            <BootstrapProvider>
              <Layout current={current} setCurrent={setCurrent}>
                <CurrentPage current={current} setCurrent={setCurrent} />
              </Layout>
            </BootstrapProvider>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
