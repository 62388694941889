import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  RefObject,
  useCallback,
} from "react";
import { Box, Flex, Text, Spacer, useTheme, Spinner } from "@chakra-ui/react";
import moment from "moment";
import { CalendarIcon } from "@chakra-ui/icons";
import Calendar from "react-calendar";
import useSWR from "swr";
import axios from "axios";

import "./date-time.css";
import "react-calendar/dist/Calendar.css";

import WizardFormHeading from "../../components/wizard-form-heading/wizard-form-heading";
import { appointmentSlotsUrl, branchesUrl, scheduleUrl } from "../../services/urls";
import AppInput from "../../components/app-input/app-input";
import AppButton from "../../components/app-button/app-button";
import { useGlobalBootstrapContext } from "../../context/bootstrap";
import {
  customSwrFetcher,
  getSessionStorage,
  reformData,
  toEnglishDateFormat,
} from "../../helper";
import AppointmentMode from "./appointment-mode";
import Doctor from "./doctor";
import TimeSlot, { Slot } from "./time-slot";
import { SelectedAreaType } from "../consultation/options";
import styled from "styled-components";

interface PropType {
  current: number;
  setCurrent: Function;
}

export interface DoctorType {
  id: number | null;
  name: string;
  role: string;
}

export interface PreferredDoctorDateAndTimeType {
  appointmentMode: string;
  slot: {
    id: string;
    name: string;
    time: string;
  };
  appointmentDate: Date | null;
  preferredDoctor: DoctorType;
  preferredLocation?: string;
}

const doctors = [
  {
    id: 1,
    name: "Dr. Wale Tone",
    role: "Pediatrician",
  },
  {
    id: 2,
    name: "Dr. Tokunbo",
    role: "Surgion",
  },
  {
    id: 3,
    name: "Dr. Ayodeji Ishola",
    role: "Surgion",
  },
  {
    id: 4,
    name: "Dr. Mark Ugbomah",
    role: "Surgion",
  },
  {
    id: 5,
    name: "Dr. Dumto Imoh",
    role: "Pediatrician",
  },
];

enum AppointmentModes {
  InPerson = "InPerson",
  Phone = "Phone",
  Chat = "Chat",
  Video = "Video",
}

const DateTime = ({ current, setCurrent }: PropType) => {
  const {
    bootstrapData: {
      data: { accessToken, mode: appointmentModes },
    },
  } = useGlobalBootstrapContext();
  const calendar: RefObject<any> = useRef(null);
  const theme = useTheme();

  const [doctorAvailable] = useState(false);
  const [preferredDoctorDateAndTime, setPreferredDoctorDateAndTime] =
    useState<PreferredDoctorDateAndTimeType>({
      appointmentMode: "",
      slot: {
        id: "",
        name: "",
        time: "",
      },
      preferredDoctor: {
        id: null,
        name: "",
        role: "",
      },
      appointmentDate: null,
    });

  const { id: serviceId }: SelectedAreaType =
    getSessionStorage("areaOfConsultation");

  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem("preferredDoctorDateAndTime")
      ? JSON.parse(sessionStorage.getItem("preferredDoctorDateAndTime") || "")
      : null;
    if (sessionStorageData) setPreferredDoctorDateAndTime(sessionStorageData);
  }, []);

  const calendarStartAndEndDate = useCallback((date?: string | Date) => {
    return {
      start: moment(date).startOf("month").subtract(1, "month").format("YYYY-MM-DD"),
      end: moment(date).endOf("month").add(1, "month").format("YYYY-MM-DD"),
    };
  }, []);

  const [startAndEnd, setStartAndEnd] = useState<{
    start: string;
    end: string;
  }>(calendarStartAndEndDate);

  const [scheduleData, setScheduleData] = useState<any>([]);

  const handleNext = () => {
    sessionStorage.setItem(
      "preferredDoctorDateAndTime",
      JSON.stringify(preferredDoctorDateAndTime)
    );
    setCurrent(current + 1);
  };

  const { data: locations, error } = useSWR(
    serviceId &&
      preferredDoctorDateAndTime.appointmentMode === AppointmentModes.InPerson
      ? `/services/${serviceId}/branches`
      : null,
    () =>
      customSwrFetcher({
        url: branchesUrl(accessToken, serviceId),
      })
  );

  useEffect(() => {
    if (locations) {
      const hospitalLocations = reformData(locations.data);
      if (hospitalLocations.length === 1) {
        setPreferredDoctorDateAndTime(
          (prevState: PreferredDoctorDateAndTimeType) => ({
            ...prevState,
            preferredLocation: hospitalLocations[0].id,
          })
        );
      }
    }
  }, [locations]);

  const { data: slots, error: slotsError } = useSWR(
    serviceId &&
      preferredDoctorDateAndTime.appointmentMode &&
      preferredDoctorDateAndTime.appointmentDate
      ? `/appointments/slots/${serviceId}/${toEnglishDateFormat(
          new Date(preferredDoctorDateAndTime.appointmentDate)
        )}${
          preferredDoctorDateAndTime.preferredLocation
            ? `&branchId=${preferredDoctorDateAndTime.preferredLocation}`
            : ""
        }`
      : null,
    () => {
      const { appointmentDate } = preferredDoctorDateAndTime;
      return customSwrFetcher({
        url: appointmentSlotsUrl({
          accessToken,
          date: toEnglishDateFormat(
            appointmentDate ? new Date(appointmentDate) : new Date()
          ),
          serviceId,
          branchId:
            preferredDoctorDateAndTime.appointmentMode === AppointmentModes.InPerson
              ? preferredDoctorDateAndTime.preferredLocation
              : undefined,
        }),
      });
    }
  );

  const availableTimeSlots = slots
    ? slots.data
        .filter(
          (slot: Slot) =>
            moment(slot.slotDate).isSameOrAfter(moment()) && slot.status !== false
        )
        .sort((a: Slot, b: Slot) => {
          if (moment(a.slotDate).isBefore(moment(b.slotDate))) {
            return -1;
          }
          if (moment(a.slotDate).isAfter(moment(b.slotDate))) {
            return 1;
          }
          return 0;
        })
    : [];

  useEffect(() => {
    const data = axios.get(
      scheduleUrl(
        accessToken,
        serviceId,
        startAndEnd.start,
        startAndEnd.end,
        preferredDoctorDateAndTime.preferredLocation
      )
    );
    data.then((res) => {
      setScheduleData(res.data?.data);
    });
  }, [
    serviceId,
    startAndEnd.start,
    startAndEnd.end,
    accessToken,
    preferredDoctorDateAndTime.preferredLocation,
  ]);

  const availableSchedules = useMemo(() => {
    const av = ((Array.isArray(scheduleData) && scheduleData) || []).reduce(
      (acc: Map<string, boolean>, item: string) => {
        const availableDate = moment(item).format("YYYY-MM-DD");
        acc.set(availableDate, true);
        return acc;
      },
      new Map<string, string>()
    );

    return av;
  }, [scheduleData]);

  const unAvailableDates = useCallback(
    (date: Date, view: string) => {
      const mDate = moment(date);

      return (
        view !== "month" ||
        !availableSchedules.has(mDate.format("YYYY-MM-DD")) ||
        mDate.isBefore(moment().startOf("day"))
      );
    },
    [availableSchedules]
  );

  return (
    <Box
      maxW={doctorAvailable ? "1000px" : "700px"}
      mx="auto"
      pb="10rem"
      px="1rem !important"
    >
      <Box mt="7.6rem" maxW="48.4rem" mx="auto" mb="4rem">
        <WizardFormHeading
          heading="Appointment Preference"
          subHeading="Please select your appointment preferences to add an event to your calender"
        />
      </Box>
      <Flex flexDir="column">
        <Flex gap="2rem" justify="center" flexWrap={{ base: "wrap", md: "unset" }}>
          {appointmentModes.map((mode: string) => (
            <AppointmentMode
              key={mode}
              mode={mode}
              preferredDoctorDateAndTime={preferredDoctorDateAndTime}
              setPreferredDoctorDateAndTime={setPreferredDoctorDateAndTime}
            />
          ))}
        </Flex>
      </Flex>

      {preferredDoctorDateAndTime.appointmentMode === "InPerson" && (
        <Box maxW="64.5rem" mx="auto" mt="4rem">
          {!locations && !error ? (
            <Flex align="center" justify="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Text>An error occurred fetching hospital location</Text>
          ) : reformData(locations.data).length === 1 ? (
            <Flex align="center" justify="center" gap="1rem" flexWrap="wrap">
              <Text fontWeight="700" color="#414141">
                Hospital Location:
              </Text>
              <Text fontWeight="500">
                {reformData(locations.data)[0].name}:{" "}
                {reformData(locations.data)[0].address1}
              </Text>
            </Flex>
          ) : (
            <AppInput
              id="location"
              label="Preferred Location"
              placeholder="Choose a location"
              isSelect
              value={preferredDoctorDateAndTime.preferredLocation}
              onChange={(e: React.FormEvent<EventTarget>): void => {
                let target = e.target as HTMLInputElement;
                setPreferredDoctorDateAndTime({
                  ...preferredDoctorDateAndTime,
                  preferredLocation: target.value,
                });
              }}
              options={reformData(locations.data).map((location) => ({
                label: ` ${location.name}: ${location.address1}, ${
                  location.state && `${location.state?.name}`
                }`,
                value: location.id,
              }))}
            />
          )}
        </Box>
      )}
      <Flex
        mt="8rem"
        flexDir={{ base: "column", md: "row" }}
        padding={{ base: "1rem", md: "0" }}
        justify="space-between"
      >
        {doctorAvailable && (
          <Box
            width={{ base: "100%", md: "unset" }}
            mx={{ base: "auto", md: "unset" }}
          >
            <Text
              fontSize="1.6rem"
              fontWeight="600"
              mb="2rem"
              textAlign={{ base: "center", md: "unset" }}
            >
              Preferred Doctor (Optional)
            </Text>
            <Box
              maxH={{ base: "30rem", md: "unset" }}
              overflowY={{ base: "scroll", md: "unset" }}
            >
              <AppInput placeholder="Search" />
              <Spacer mb="2rem" />
              {doctors.map((doctor) => (
                <Doctor
                  key={doctor.id}
                  preferredDoctorDateAndTime={preferredDoctorDateAndTime}
                  setPreferredDoctorDateAndTime={setPreferredDoctorDateAndTime}
                  doctor={doctor}
                />
              ))}
            </Box>
          </Box>
        )}
        <Flex
          mt={{ md: "unset" }}
          mx={{ base: "auto", md: "unset" }}
          align="center"
          flexDir={"column"}
        >
          <Text
            fontSize="2rem"
            fontWeight="700"
            mb="2rem"
            textAlign={{ base: "center", md: "unset" }}
            color={`${theme.colors.brand.main}`}
          >
            Appointment Date / Time
          </Text>
          <CalendarContainer color={`${theme.colors.brand.main}`}>
            <Calendar
              inputRef={calendar}
              tileDisabled={({ date, view }) => {
                return view === "month" ? unAvailableDates(date, view) : false;
              }}
              showFixedNumberOfWeeks
              tileClassName={({ date, view }) => {
                return view === "month"
                  ? unAvailableDates(date, view)
                    ? "disabled_date"
                    : "calendar_day"
                  : "calendar_day";
              }}
              onActiveStartDateChange={({ activeStartDate }) => {
                setStartAndEnd(calendarStartAndEndDate(activeStartDate));
              }}
              minDate={new Date()}
              onChange={(value: Date) => {
                value.setHours(0, 0, 0, 0);
                setPreferredDoctorDateAndTime(
                  (prevState: PreferredDoctorDateAndTimeType) => ({
                    ...prevState,
                    appointmentDate: value,
                  })
                );
              }}
              value={
                preferredDoctorDateAndTime.appointmentDate
                  ? new Date(preferredDoctorDateAndTime.appointmentDate)
                  : undefined
              }
            />
          </CalendarContainer>
        </Flex>
        <Box mt={{ base: "4rem", md: "unset" }}>
          <Text fontSize="1.6rem" fontWeight="600" mb="2rem" textAlign="center">
            {preferredDoctorDateAndTime.appointmentDate
              ? moment(preferredDoctorDateAndTime.appointmentDate).format(
                  "dddd, MMMM Do YYYY"
                )
              : ""}
          </Text>
          <Flex
            flexDir={{ base: "row", md: "column" }}
            gap="1rem"
            flexWrap={{ base: "wrap", md: "unset" }}
            align="center"
            overflowY="auto"
            maxH="40rem"
            maxW="30rem"
            mx="auto"
          >
            {preferredDoctorDateAndTime.appointmentDate ? (
              !slots && !slotsError ? (
                <Spinner />
              ) : slotsError ? (
                <Text>{slotsError?.response?.data.message}</Text>
              ) : availableTimeSlots.length === 0 ? (
                <Text>No available time slot!</Text>
              ) : (
                availableTimeSlots.map(
                  (slot: Slot) =>
                    slot.status && (
                      <TimeSlot
                        key={slot.slotDate}
                        slot={slot}
                        preferredDoctorDateAndTime={preferredDoctorDateAndTime}
                        setPreferredDoctorDateAndTime={setPreferredDoctorDateAndTime}
                      />
                    )
                )
              )
            ) : null}
          </Flex>
        </Box>
      </Flex>
      <Flex justify="flex-end" mt="3rem">
        <Flex gap="1rem">
          <AppButton
            variant="secondary"
            onClick={() => setCurrent(current && current - 1)}
          >
            Back
          </AppButton>
          <AppButton
            disabled={
              !preferredDoctorDateAndTime.appointmentMode ||
              !preferredDoctorDateAndTime.appointmentDate ||
              !preferredDoctorDateAndTime.slot.id ||
              (preferredDoctorDateAndTime.appointmentMode ===
                AppointmentModes.InPerson &&
                !preferredDoctorDateAndTime.preferredLocation) ||
              (!preferredDoctorDateAndTime.preferredDoctor.id && doctorAvailable)
            }
            variant="primary"
            onClick={handleNext}
          >
            <Flex align="center" gap="1.5rem">
              <Text>Schedule Appointment</Text>
              <CalendarIcon />
            </Flex>
          </AppButton>
        </Flex>
      </Flex>
    </Box>
  );
};

export default DateTime;

const CalendarContainer = styled.div<{
  color: string;
}>`
  .calendar_day {
    color: ${(props) => props.color};
  }

  .disabled_date {
    color: #757575;
  }
`;
