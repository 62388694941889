export const baseUrl = `${process.env.REACT_APP_API_ENDPOINT_URL}`;

export const appointmentSlotsUrl = ({
  accessToken,
  date,
  serviceId,
  branchId,
}: {
  accessToken: string;
  date: string;
  serviceId: string;
  branchId?: string;
}) =>
  `${baseUrl}appointments/slots?access_token=${accessToken}&serviceId=${serviceId}&date=${date}${
    branchId ? `&branchId=${branchId}` : ""
  }`;
export const branchesUrl = (accessToken: string, serviceId: string) =>
  `${baseUrl}services/${serviceId}/branches?access_token=${accessToken}`;
export const bootstrapUrl = (accessToken: string) =>
  `${baseUrl}calendar/settings?access_token=${accessToken}`;
export const appointmentUrl = (accessToken: string) =>
  `${baseUrl}appointments?access_token=${accessToken}`;
export const reasonForVisitUrl = (accessToken: string) =>
  `${baseUrl}appointments/reasons?access_token=${accessToken}`;
export const scheduleUrl = (
  accessToken: string,
  serviceId: string,
  startDate: string,
  endDate: string,
  branchId?: string
) =>
  `${baseUrl}services/${serviceId}/schedules?access_token=${accessToken}&startDate=${startDate}&endDate=${endDate}${
    branchId ? `&branchId=${branchId}` : ""
  }`;
