import moment from "moment";
import { Text, Flex, Image, Box, useTheme } from "@chakra-ui/react";
import { getSessionStorage } from "../../helper/index";
import { Input } from "../details-form/details-form";
import { formatTime } from "../../helper/index";
import { QRCodeCanvas } from "qrcode.react";
import { SelectedAreaType } from "../consultation/options";
import { PreferredDoctorDateAndTimeType } from "../date-time/date-time";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
import { PatientDetails } from "../../components/booking-slip";

export interface TimeAndDateProps {
  appointmentDate: string;
  appointmentTime: string;
  preferredDoctor: {
    id: string;
    name: string;
  };
}

const ConfirmDetails = () => {
  const {
    appointmentDate,
    preferredDoctor,
    appointmentMode,
    slot: { time },
  }: PreferredDoctorDateAndTimeType = getSessionStorage(
    "preferredDoctorDateAndTime"
  );

  const {
    detail,
    email,
    firstName,
    lastName,
    phoneNumber,
    reasonForVisit,
    dateOfBirth,
    gender,
    regNum,
  }: PatientDetails = getSessionStorage("patientDetails");

  const { name }: SelectedAreaType = getSessionStorage("areaOfConsultation");
  const bookingId: string = getSessionStorage("bookingId");
  const meetingId: string = getSessionStorage("meetingId");

  const {
    colors: {
      brand: { main },
    },
  } = useTheme();

  return (
    <Flex
      id="booking-slip"
      direction="column"
      maxWidth="703px"
      mx={{ base: "0.8rem", md: "auto" }}
      gap={{ base: "4rem", md: "5.8rem" }}
      px="4rem"
    >
      {bookingId && (
        <Flex
          justify="center"
          mt="9.6rem"
          mb="4.7rem"
          fontSize={{ base: "2.0rem", md: "3.2rem" }}
          fontWeight="600"
          gap="1.5rem"
        >
          <Text color={`${main}`}>Booking ID:</Text>
          <Text>{bookingId}</Text>
        </Flex>
      )}
      <Flex align="center" fontSize="14px">
        <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
          <Image src="./assets/icons/user.svg" alt="user" />
          <Text>Full Name</Text>
        </Flex>
        <Box flex="1">
          <Text display="inline">{firstName}, </Text>
          <Text display="inline" fontWeight="700" textDecoration="uppercase">
            {lastName}
          </Text>
        </Box>
      </Flex>
      <Flex align="center" fontSize="14px">
        <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
          <Image src="./assets/icons/email.svg" alt="user" />
          <Text>Gender</Text>
        </Flex>
        <Text flex="1" textTransform={"capitalize"}>
          {gender}
        </Text>
      </Flex>
      <Flex align="center" fontSize="14px">
        <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
          <Image src="./assets/icons/email.svg" alt="user" />
          <Text>Email Address</Text>
        </Flex>
        <Text flex="1">{email}</Text>
      </Flex>

      <Flex align="center" fontSize="14px">
        <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
          <Image src="./assets/icons/phone.svg" alt="user" />
          <Text>Phone Number</Text>
        </Flex>
        <Text flex="1">{phoneNumber}</Text>
      </Flex>
      <Flex align="center" fontSize="14px">
        <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
          <Image src="./assets/icons/dob.svg" alt="user" />
          <Text>Date of Birth</Text>
        </Flex>
        <Text flex="1">{`${moment(dateOfBirth).format("DD/MM/YY")}`}</Text>
      </Flex>
      <Flex align="center" fontSize="14px">
        <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
          <MdOutlineFilterCenterFocus color="#414141" size="2.5rem" />
          <Text>Area of Consultation</Text>
        </Flex>
        <Text flex="1">{name}</Text>
      </Flex>
      <Flex align="center" fontSize="14px">
        <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
          <Image src="./assets/icons/mode.svg" alt="user" />
          <Text>Mode</Text>
        </Flex>
        <Text flex="1">{appointmentMode}</Text>
      </Flex>
      <Flex align="center" fontSize="14px">
        <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
          <Image src="./assets/icons/date.svg" alt="user" />
          <Text>Date</Text>
        </Flex>
        <Text flex="1">{`${moment(appointmentDate).format("DD/MM/YY")}`}</Text>
      </Flex>
      <Flex align="center" fontSize="14px">
        <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
          <Image src="./assets/icons/time.svg" alt="user" />
          <Text>Time</Text>
        </Flex>
        <Flex flex="1" gap=".4rem">
          <Text display="inline">{time} </Text>
          <Text fontWeight="700" display="inline">
            WAT
          </Text>
        </Flex>
      </Flex>
      {regNum && (
        <Flex align="center" fontSize="14px">
          <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
            <Image src="./assets/icons/emr.svg" alt="user" />
            <Text>Hospital Reg/EMR Number</Text>
          </Flex>
          <Text flex="1">{regNum}</Text>
        </Flex>
      )}

      {preferredDoctor.id && (
        <Flex align="center" fontSize="14px">
          <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
            <Image src="./assets/icons/with.svg" alt="user" />
            <Text>Meeting With</Text>
          </Flex>
          <Text flex="1">{preferredDoctor.name}</Text>
        </Flex>
      )}
      <Flex align="center" fontSize="14px">
        <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
          <Image src="./assets/icons/reason.svg" alt="user" />
          <Text>Reason for Visit</Text>
        </Flex>
        <Text flex="1" textTransform={"capitalize"}>
          {reasonForVisit.reason}
        </Text>
      </Flex>
      {detail && (
        <Flex align="center" fontSize="14px">
          <Flex flex="1" align="center" gap={{ base: "1.2rem", md: "2.8rem" }}>
            <Image src="./assets/icons/details.svg" alt="user" />
            <Text>Details</Text>
          </Flex>
          <Text flex="1">{detail}</Text>
        </Flex>
      )}
      <Flex align="center" justify="center">
        {meetingId && (
          <QRCodeCanvas
            id="qr-code"
            value={meetingId}
            size={200}
            bgColor="#FFF"
            fgColor="#000"
            includeMargin
            level={"H"}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ConfirmDetails;
