import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import ErrorDisplay from "./errors";

const Home = () => {
  return (
    <Box>
      <ErrorDisplay />
      <Outlet />
    </Box>
  );
};

export default Home;
