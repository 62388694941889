import { useMemo, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { ColorRing } from "react-loader-spinner";
import { PropType } from "./index";
import AppButton from "../../components/app-button/app-button";
import { getSessionStorage } from "../../helper/index";
import { useGetServices } from "../../hooks";
import Consultations from "./consultations";

type ServicesType = {
  node: {
    id: string;
    photo: string;
    alias: string;
    code: string;
    coverageArea: string;
    appointmentRequired: boolean;
    name: string;
    status: boolean;
    description: string;
  };
};

export type SelectedAreaType = { id: string; name: string };

export type ConsultationTypes = ReturnType<typeof areaOfConsultation>;

export const areaOfConsultation = (services: ServicesType[]) => {
  return services?.map((service) => {
    const { id, photo, name, description, alias } = service.node;
    return { id, name, photo, description, alias };
  });
};

const Options = ({ current, setCurrent }: PropType) => {
  const [param, setParam] = useState({});
  const { response, isLoading, error } = useGetServices(param);

  const areaOfConsultations = useMemo(
    () => areaOfConsultation(response?.pageEdges as ServicesType[]),
    [response]
  );

  const handleCancel = () => {
    window.history.back();
  };

  const [selectedArea, setSelectedArea] = useState<SelectedAreaType>(
    getSessionStorage("areaOfConsultation")
  );

  const handleSubmit = () => {
    sessionStorage.setItem("areaOfConsultation", JSON.stringify(selectedArea));
    setCurrent(current + 1);
  };

  const previous = response?.pageCursors?.previous?.cursor || "";
  const nextLoad = response?.pageCursors?.next?.cursor || "";
  const handleNextLoad = () => setParam({ cursor: nextLoad });
  const handlePreviousLoad = () => setParam({ cursor: previous });

  return (
    <Box
      maxWidth="102.4rem"
      width="100%"
      mx="auto"
      mt={{ base: "4.8rem", md: "7.6rem" }}
    >
      {isLoading ? (
        <Flex w="100vw" h="100vh" align="center" justify="center">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </Flex>
      ) : (
        <>
          {error ? (
            <Flex align="center">
              <Text>{error.message || "An error occurred while fetching data"}</Text>
            </Flex>
          ) : (
            <Flex flexDirection="column" gap="1.5rem" align={"center"}>
              <Consultations
                selectedArea={selectedArea}
                setSelectedArea={setSelectedArea}
                consultations={areaOfConsultations}
              />

              {response?.totalCount > 25 && (
                <Flex gap="1rem">
                  <AppButton
                    variant="secondary"
                    onClick={handlePreviousLoad}
                    disabled={previous === ""}
                  >
                    Prev
                  </AppButton>
                  <AppButton
                    variant="secondary"
                    onClick={handleNextLoad}
                    disabled={nextLoad === ""}
                  >
                    Next
                  </AppButton>
                </Flex>
              )}

              <Flex
                w={{ base: "100%", md: "80%" }}
                mt="8.2rem"
                mb="2.4rem"
                mr={{ base: "1.6rem", md: "6.4rem" }}
                justify="end"
                gap="1.5rem"
              >
                <AppButton onClick={handleCancel} variant="secondary">
                  Cancel
                </AppButton>
                <AppButton
                  onClick={handleSubmit}
                  variant="primary"
                  disabled={!selectedArea}
                >
                  Proceed
                </AppButton>
              </Flex>
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default Options;
