import Options from "./options";
import WizardFormHeading from "../../components/wizard-form-heading/wizard-form-heading";

export interface PropType {
  current: number;
  setCurrent: (current: number) => void;
}

const Consultation = ({ current, setCurrent }: PropType) => {
  return (
    <main>
      <WizardFormHeading
        heading="Area of Consultation"
        subHeading="What service do you require?"
      />
      <Options current={current} setCurrent={setCurrent} />
    </main>
  );
};

export default Consultation;
