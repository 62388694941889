import * as yup from "yup";
import "yup-phone";
import { isValidPhoneNumber } from "react-phone-number-input";

export const patientDetailsSchema = yup.object().shape({
  firstName: yup.string().required("First name is required!"),
  lastName: yup.string().required("Last name is required!"),
  gender: yup.string().required("Gender is required!"),
  phoneNumber: yup
    .string()
    .phone("NG", true, "Must be a valid phone number")
    .required("Phone is required!")
    .test({
      name: "validDate",
      exclusive: false,
      params: {},
      message: "Must me a valid phone number",
      test: function (value: any): boolean {
        if (value.startsWith("+234") && value.length !== 14) return false;
        return isValidPhoneNumber(value ? value : "");
      },
    }),
  email: yup.string().email("Must be a valid email!").required("Email is required!"),
  confirmEmail: yup
    .string()
    .email("Must be a valid email!")
    .required("Confirm email is required!")
    .oneOf([yup.ref("email"), null], "Emails must match!"),
  reasonId: yup.string().required("Reason for visit is required!"),
  dateOfBirth: yup.string().required("Date of Birth is required!"),
  detail: yup.string(),
  regNum: yup.string(),
});
