/* eslint-disable @typescript-eslint/dot-notation */
import { useMemo, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import useSWR from "swr";

import AppButton from "../../components/app-button/app-button";
import AppInput from "../../components/app-input/app-input";
import WizardFormHeading from "../../components/wizard-form-heading/wizard-form-heading";
import { patientDetailsSchema } from "../../schema/schema";
import { getSessionStorage, setItem } from "../../helper";
import { reasonForVisitUrl } from "../../services/urls";
import { useGlobalBootstrapContext } from "../../context/bootstrap";

interface PropType {
  current: number;
  setCurrent: Function;
}

export interface Input {
  firstName: string;
  lastName: string;
  gender: string;
  phoneNumber: string;
  email: string;
  confirmEmail: string;
  reasonId: string;
  dateOfBirth: string;
  regNum: string;
  detail: string;
  [key: string]: string | any;
}

type Reasons = {
  node: {
    reason: string;
    id: string;
  };
};

const DetailsForm = ({ current, setCurrent }: PropType) => {
  const [reasonForVisit, setReasonForVisit] = useState<{ [id: string]: string }>({});
  const { accessToken } = useGlobalBootstrapContext();

  const possiblyFilledInputValues: Input = getSessionStorage("patientDetails");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Input>({
    resolver: yupResolver(patientDetailsSchema),
    defaultValues: possiblyFilledInputValues,
  });

  const handleNext: SubmitHandler<Input> = async (data: Input) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (!data[key] || !data[key]?.length) delete data[key];
      });
      setItem("patientDetails", {
        ...data,
        reasonForVisit: {
          id: data.reasonId,
          reason: reasonForVisit[data.reasonId],
        },
      });
      setCurrent(current + 1);
    }
  };

  const reasonsFetcher = async (): Promise<Reasons[]> => {
    const response = await axios(reasonForVisitUrl(accessToken!));
    return response.data.data.pageEdges;
  };

  const { data: reasonsForVisit } = useSWR(`${accessToken}`, reasonsFetcher);

  const reasons = useMemo(() => {
    if (!reasonsForVisit) return [];
    return reasonsForVisit.map((_data) => {
      const { reason, id } = _data?.node;
      setReasonForVisit((prevState) => ({ ...prevState, [id]: reason }));
      return {
        label: reason,
        value: id,
      };
    });
  }, [reasonsForVisit]);

  const validAge: number = 18;
  const validAgeDate = new Date().setFullYear(new Date().getFullYear() - validAge);

  return (
    <Box maxW="800px" mx="auto" pb="8rem">
      <Box mt="7.6rem" maxW="48.4rem" mx="auto" mb="4rem">
        <WizardFormHeading heading="Enter your Details" />
      </Box>
      <form onSubmit={handleSubmit(handleNext)}>
        <Flex
          padding={{ base: "1rem", md: "0" }}
          justify="space-between"
          alignItems="center"
          flexDir="column"
          gap="4rem"
        >
          <Flex
            flexDir={{ base: "column", md: "row" }}
            justify="space-between"
            align="center"
            width="100%"
            gap="3rem"
          >
            <AppInput
              register={register("firstName")}
              label="First Name"
              placeholder="Felix"
              id="firstname"
              errorMessage={errors.firstName?.message}
              isRequired
            />
            <AppInput
              register={register("lastName")}
              errorMessage={errors.firstName?.message}
              label="Last Name"
              placeholder="Doe"
              id="lastname"
              isRequired
            />
          </Flex>
          <Flex
            flexDir={{ base: "column", md: "row" }}
            justify="space-between"
            align="center"
            width="100%"
            gap="3rem"
          >
            <AppInput
              label="Gender"
              errorMessage={errors.gender?.message}
              placeholder="Select Gender"
              register={register("gender")}
              id="gender"
              isSelect
              isRequired
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]}
            />
            <AppInput
              register={register("phoneNumber")}
              errorMessage={errors.phoneNumber?.message}
              label="Mobile Number"
              placeholder="090344566788"
              id="phoneNumber"
              isPhone
              control={control}
              isRequired
            />
          </Flex>
          <Flex
            flexDir={{ base: "column", md: "row" }}
            justify="space-between"
            align="center"
            width="100%"
            gap="3rem"
          >
            <AppInput
              label="Email"
              register={register("email")}
              errorMessage={errors.email?.message}
              placeholder="johndoe@mail.com"
              id="email"
              isRequired
              type="email"
            />
            <AppInput
              label="Confirm email"
              register={register("confirmEmail")}
              errorMessage={errors.confirmEmail?.message}
              placeholder="johndoe@mail.com"
              id="confirmEmail"
              isRequired
            />
          </Flex>
          <Flex
            flexDir={{ base: "column", md: "row" }}
            justify="space-between"
            align="center"
            width="100%"
            gap="3rem"
          >
            <AppInput
              label="Hospital Reg/EMR Number  (optional)"
              register={register("regNum")}
              placeholder="AB000234"
              id="regNum"
            />
            <AppInput
              label="Date of Birth"
              register={register("dateOfBirth")}
              placeholder="Select Date of Birth"
              maxDate={new Date(validAgeDate).toISOString().split("T")[0]}
              id="dateOfBirth"
              isDate
              isRequired
            />
          </Flex>
          <Text
            mt="4rem"
            mb="-3rem"
            fontWeight="700"
            fontSize="1.7rem"
            color="#424242"
            alignSelf="start"
          >
            Details for your Doctor
          </Text>
          <Text alignSelf="start" color="#888" fontWeight="400" fontSize="1.4rem">
            Describe why you are seeing a doctor
          </Text>

          <AppInput
            isSelect
            isRequired
            placeholder="Reason"
            label="Reason for Visit"
            errorMessage={errors.reasonId?.message}
            register={register("reasonId")}
            options={reasons || []}
            id="reasonForVisit"
          />
          <AppInput
            isTextArea
            register={register("detail")}
            placeholder="Input Text"
            label="Details (Optional)"
            id="detail"
          />
          <Flex alignSelf="end" gap="1.5rem">
            <AppButton
              variant="secondary"
              onClick={() => setCurrent(current && current - 1)}
            >
              Back
            </AppButton>
            <AppButton type="submit" variant="primary" onClick={handleNext}>
              Proceed
            </AppButton>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};

export default DetailsForm;
