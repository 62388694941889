import { ModalBody, ModalCloseButton, Image, Text, Flex } from "@chakra-ui/react";
import moment from "moment";

import { getSessionStorage } from "../../helper/index";
import { formatTime } from "../../helper/index";
import AppButton from "../../components/app-button/app-button";
import { PreferredDoctorDateAndTimeType } from "../date-time/date-time";
import { PDFDownloadLink } from "@react-pdf/renderer";
import BookingSlip from "../../components/booking-slip";
import { Input } from "../details-form/details-form";
import html2canvas from "html2canvas";

interface PropType {
  setPopupOpen: (popupOpen: boolean) => void;
  setViewDetails: (viewDetails: boolean) => void;
  setCurrent: (current: number) => void;
  viewDetails: boolean;
}
const BookingState = ({
  setPopupOpen,
  setViewDetails,
  viewDetails,
  setCurrent,
}: PropType) => {
  const { firstName }: Input = getSessionStorage("patientDetails");
  const {
    appointmentDate,
    slot: { time },
  }: PreferredDoctorDateAndTimeType = getSessionStorage(
    "preferredDoctorDateAndTime"
  );

  const handleDownloadImage = async () => {
    const bookinSlip = document.getElementById("booking-slip");
    const canvas = await html2canvas(bookinSlip as HTMLElement);
    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = `${firstName}-${moment(appointmentDate).format(
        "Do of MMMM, YYYY"
      )}-appointment-slip.jpg`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  return (
    <>
      <Image
        src="./assets/icons/confirm.svg"
        alt="Confirm"
        width="9.6rem"
        height="9.6rem"
        mx="auto"
        mt="8.9rem"
        mb="4.8rem"
      />
      {viewDetails && (
        <ModalCloseButton
          width="0.8rem"
          height="0.8rem"
          position="absolute"
          top="4.8rem"
          right="4.8rem"
          outline="none"
          onClick={() => {
            sessionStorage.clear();
          }}
        />
      )}
      <ModalBody>
        <Text
          fontWeight="700"
          color="brand.main"
          fontSize="1.6rem"
          mb="0.8rem"
          textAlign="center"
        >
          Congratulations!
        </Text>
        <Text fontSize="1.4rem" mx="6rem" textAlign="center">
          You have successfully booked your appointment for the{" "}
        </Text>
        <Text fontSize="1.4rem" textAlign="center" fontWeight={"700"}>
          {moment(appointmentDate).format("Do of MMMM, YYYY")}
        </Text>
        <Text fontSize="1.4rem" textAlign="center">
          by
        </Text>
        <Text fontSize="1.4rem" textAlign="center" fontWeight={"700"}>
          {time}.
        </Text>
        <Text fontSize="1.4rem" textAlign="center">
          A confirmation slip has been sent to your email address. You can view your
          booking slip by clicking on the button below.
        </Text>
      </ModalBody>

      <Flex
        mt="6.4rem"
        mb="2.4rem"
        justify="center"
        gap="1.5rem"
        fontSize="1.4rem"
        fontWeight="500"
        flexDir="column"
        align="center"
      >
        <AppButton
          variant="primary"
          width="350px"
          onClick={() => {
            // setPopupOpen(true);
            // setViewDetails(true);
          }}
        >
          <PDFDownloadLink
            document={<BookingSlip />}
            fileName={`${firstName}-${moment(appointmentDate).format(
              "Do of MMMM, YYYY"
            )}-appointment-slip`}
          >
            {({ loading }) =>
              loading ? "Loading document..." : "Download Slip as PDF!"
            }
          </PDFDownloadLink>
        </AppButton>
        <AppButton width="350px" variant="primary" onClick={handleDownloadImage}>
          Download Slip as Image
        </AppButton>
      </Flex>
    </>
  );
};

export default BookingState;
