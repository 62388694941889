import { Button, useTheme } from "@chakra-ui/react";

interface PropType {
  children: JSX.Element | JSX.Element[] | string;
  type?: "button" | "submit" | "reset" | undefined;
  onClick: Function;
  disabled?: boolean;
  variant: "primary" | "secondary";
  width?: string;
  loading?: boolean;
}

const AppButton = ({
  children,
  type,
  onClick,
  disabled,
  variant,
  width,
  loading,
}: PropType) => {
  const {
    colors: {
      brand: { mainRGB, main },
      typography: { dynamicTextColor },
    },
  } = useTheme();
  return (
    <Button
      w={width}
      type={type}
      disabled={disabled}
      fontSize="1.4rem"
      fontWeight="500"
      padding="1.2rem"
      height="4.2rem"
      background={
        variant === "primary"
          ? `${main}`
          : variant === "secondary"
          ? `rgba(${mainRGB}, .1)`
          : ""
      }
      _hover={{
        background:
          variant === "primary"
            ? `rgba(${mainRGB}, .8)`
            : variant === "secondary"
            ? `rgba(${mainRGB}, .2)`
            : "",
      }}
      color={
        variant === "primary"
          ? `${dynamicTextColor}`
          : variant === "secondary"
          ? "#000"
          : ""
      }
      borderRadius=".6rem"
      isLoading={loading}
      onClick={() => onClick()}
    >
      {children}
    </Button>
  );
};

export default AppButton;
