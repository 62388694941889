import { Box, Heading, Text } from "@chakra-ui/react";

interface PropType {
  heading: string;
  subHeading?: string;
}

const WizardFormHeading = ({ heading, subHeading }: PropType) => {
  return (
    <Box>
      <Heading
        textAlign="center"
        mb=".8rem"
        color="brand.main"
        fontSize="2.2rem"
        fontWeight="700"
      >
        {heading}
      </Heading>
      {subHeading && (
        <Text color="#525252" textAlign="center" fontSize="1.6rem">
          {subHeading}
        </Text>
      )}
    </Box>
  );
};

export default WizardFormHeading;
