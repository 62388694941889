import { extendTheme } from "@chakra-ui/react";
import Color from "color";

const isDark = (brandColor: string): boolean => {
  const luminousValue = Number(Color(brandColor).luminosity().toFixed(1));
  return luminousValue <= 0.5;
};

export const appTheme = (data: any) => {
  const color = Color(data?.data?.color);

  const colors = {
    brand: {
      main: color.rgb(),
      mainRGB: color.rgb().array().join(" , "),
      transparent: `${color.fade(0.1)}`,
      sub1: "#047168",
      sub2: "#0CAA7D",
      light: "#E6F5FF",
    },
    typography: {
      black: "#000",
      gray: "#525252",
      dynamicTextColor: isDark(`${data?.data?.color}`) ? "#fff" : "#000",
    },
  };
  const styles = {
    global: {
      html: {
        fontSize: "62.5%",
      },
      body: {
        fontFamily: "'Montserrat', sans-serif",
        color: "#000",
        fontSize: "1.6rem",
        lineHeight: "1.6",
        fontWeight: "400",
      },
      "*, *::before, *::after": {
        margin: "0",
        padding: "0",
        boxSizing: "border-box",
      },
    },
  };
  return extendTheme({ colors, styles });
};

const colors = {
  brand: {
    main: "rgba(12, 170, 125, 1)",
    mainRGB: "12, 170, 125",
    transparent: "rgba(12, 170, 125, 0.1)",
    sub1: "#047168",
    sub2: "#0CAA7D",
    light: "#E6F5FF",
  },
  typography: {
    black: "#000",
    gray: "#525252",
    dynamicTextColor: isDark("rgba(12, 170, 125, 1)") ? "#fff" : "#000",
  },
};

const styles = {
  global: {
    html: {
      fontSize: "62.5%",
    },
    body: {
      fontFamily: "'Montserrat', sans-serif",
      color: "#000",
      fontSize: "1.6rem",
      lineHeight: "1.6",
      fontWeight: "400",
    },
    "*, *::before, *::after": {
      margin: "0",
      padding: "0",
      boxSizing: "border-box",
    },
  },
};

export default extendTheme({ colors, styles });
