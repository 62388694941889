import { startTransition, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  ModalCloseButton,
  Box,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import BookingState from "./booking-state";
import BookSlip from "./book-slip";
import AppButton from "../../components/app-button/app-button";
import { Input } from "../details-form/details-form";
import { getSessionStorage } from "../../helper";
import { CurrentSteps } from "../breadcrumb";
import { PreferredDoctorDateAndTimeType } from "../date-time/date-time";
import moment from "moment";

interface PropType {
  setPopupOpen: (popupOpen: boolean) => void;
  popupOpen: boolean;
  setCurrent: (current: number) => void;
}

const Popup = ({ setPopupOpen, popupOpen, setCurrent }: PropType) => {
  const bookSlipRef = useRef(null);
  const closePopup = () => {
    startTransition(() => {
      setPopupOpen(false);
      if (viewDetails) {
        setViewDetails(false);
      }
      sessionStorage.clear();
      setCurrent(CurrentSteps.FirstStep);
    });
  };
  const [viewDetails, setViewDetails] = useState(false);
  const { firstName }: Input = getSessionStorage("patientDetails");

  const { appointmentDate }: PreferredDoctorDateAndTimeType = getSessionStorage(
    "preferredDoctorDateAndTime"
  );
  return (
    <>
      <Modal isOpen={popupOpen} onClose={closePopup}>
        <ModalOverlay />
        <ModalContent
          maxWidth={viewDetails ? "101.7rem" : "50.4rem"}
          width="100%"
          position="relative"
        >
          <ModalCloseButton
            width="0.8rem"
            height="0.8rem"
            position="absolute"
            top={{ base: "1.8rem", md: "3rem" }}
            right={{ base: "1.8rem", md: "2.4rem" }}
            outline="none"
          />
          {!viewDetails ? (
            <BookingState
              setPopupOpen={setPopupOpen}
              setViewDetails={setViewDetails}
              viewDetails={viewDetails}
              setCurrent={setCurrent}
            />
          ) : (
            <Flex flexDir="column" gap="4rem">
              <Box ref={bookSlipRef}>
                <BookSlip />
              </Box>
              <Flex
                justify="end"
                mt="1.7rem"
                mb="1.2rem"
                mr={{ base: "1rem", md: "7rem" }}
                gap="2.4rem"
              >
                <AppButton variant="secondary" onClick={() => closePopup()}>
                  Close
                </AppButton>

                <ReactToPrint
                  documentTitle={`${firstName}-${moment(appointmentDate).format(
                    "Do of MMMM, YYYY"
                  )}-appointment-slip`}
                  trigger={() => (
                    <AppButton variant="primary" onClick={() => null}>
                      Print
                    </AppButton>
                  )}
                  content={() => bookSlipRef.current}
                />
              </Flex>
            </Flex>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Popup;
