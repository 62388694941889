import { startTransition } from "react";
import { Box, useTheme } from "@chakra-ui/react";
import bcrumb from "./breadcrumb.module.css";
import { PropType } from "./layout";
import { getSessionStorage } from "../helper";

export enum CurrentSteps {
  FirstStep = 0,
  SecondStep = 1,
  ThirdStep = 2,
  FourthStep = 3,
}

const Breadcrumb = ({ current, setCurrent }: Omit<PropType, "children">) => {
  const theme = useTheme();
  const done = theme.colors.brand.main;
  const active = `rgba(${theme.colors.brand.mainRGB}, 0.2)`;
  const spanColor = (index: number): { color: string } => {
    return {
      color: `${
        current <= index ? "#000" : theme.colors.typography.dynamicTextColor
      }`,
    };
  };
  return (
    <Box
      maxWidth="850px"
      width="100%"
      marginLeft="auto"
      marginRight="auto"
      mb={{ base: "4.8rem", md: "7.6rem" }}
    >
      <ol className={bcrumb.breadcrumb}>
        <li
          className={` ${current > CurrentSteps.FirstStep && bcrumb.done}`}
          style={{
            backgroundColor: `${current === CurrentSteps.FirstStep ? active : done}`,
          }}
          onClick={() => startTransition(() => setCurrent(CurrentSteps.FirstStep))}
        >
          <span style={spanColor(CurrentSteps.FirstStep)}>Area of Consultation</span>
          <span style={spanColor(CurrentSteps.FirstStep)}>
            Where you want attention for
          </span>
        </li>
        <li
          className={` ${current > CurrentSteps.SecondStep && bcrumb.done} ${
            current < CurrentSteps.SecondStep && bcrumb.undone
          }`}
          style={{
            backgroundColor: `${
              current === CurrentSteps.SecondStep
                ? active
                : current < CurrentSteps.SecondStep
                ? "#d2d6dc"
                : done
            }`,
          }}
          onClick={() =>
            getSessionStorage("areaOfConsultation") &&
            setCurrent(CurrentSteps.SecondStep)
          }
        >
          <span style={spanColor(CurrentSteps.SecondStep)}>
            Appointment Preferences
          </span>
          <span style={spanColor(CurrentSteps.SecondStep)}>
            Choose an available period and mode
          </span>
        </li>
        <li
          className={` ${current < CurrentSteps.ThirdStep && bcrumb.undone}`}
          style={{
            backgroundColor: `${
              current >= CurrentSteps.ThirdStep ? active : "#d2d6dc"
            }`,
          }}
          onClick={() =>
            getSessionStorage("preferredDoctorDateAndTime") &&
            setCurrent(CurrentSteps.ThirdStep)
          }
        >
          <span style={spanColor(CurrentSteps.FourthStep)}>Details</span>
          <span style={spanColor(CurrentSteps.FourthStep)}>
            Enter relevant information
          </span>
        </li>
      </ol>
    </Box>
  );
};

export default Breadcrumb;
