import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";
import { SelectedAreaType } from "../../appointments/consultation/options";
import { Input } from "../../appointments/details-form/details-form";
import { formatTime, getSessionStorage } from "../../helper";

const styles = StyleSheet.create({
  header: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "20px",
  },
  heading: {
    fontSize: "32px",
  },
  headingA: {
    color: "#0EAA98",
  },
  headingB: {
    color: "#414141",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  flexAlignTop: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  ml12: {
    padding: "18px",
  },
  mb20: {
    marginBottom: "-10px",
  },
  iconSize: {
    height: "20px",
    width: "20px",
  },
  boldText: {
    fontWeight: "bold",
  },
  upperCase: {
    textTransform: "uppercase",
  },
});

export interface PatientDetails extends Input {
  reasonForVisit: { id: string; reason: string };
}

const BookingSlip = () => {
  const bookingId: string = getSessionStorage("bookingId");
  const {
    appointmentDate,
    preferredDoctor,
    appointmentMode,
    slot: { time },
  } = getSessionStorage("preferredDoctorDateAndTime");

  const {
    detail,
    email,
    firstName,
    lastName,
    phoneNumber,
    reasonForVisit,
    dateOfBirth,
    regNum,
    gender,
  }: PatientDetails = getSessionStorage("patientDetails");

  const { name }: SelectedAreaType = getSessionStorage("areaOfConsultation");

  const canvasUrl = (
    document.getElementById("qr-code") as HTMLCanvasElement
  ).toDataURL();

  return (
    <Document>
      <Page size="A4" style={{ color: "#000", fontSize: "14px" }}>
        <View style={styles.header}>
          <Text style={{ ...styles.heading, ...styles.headingA }}>Booking ID: </Text>
          <Text style={{ ...styles.headingB, ...styles.heading }}>{bookingId} </Text>
        </View>
        <View
          style={{ paddingLeft: "50px", paddingRight: "50px", paddingTop: "30px" }}
        >
          <View
            style={{
              ...styles.flexAlignCenter,
              ...styles.spaceBetween,
              ...styles.mb20,
            }}
          >
            <View style={{ ...styles.flexAlignCenter }}>
              <Image src="./assets/images/user.png" style={{ ...styles.iconSize }} />
              <Text style={styles.ml12}>Full Name</Text>
            </View>
            <View style={{ ...styles.flexAlignCenter }}>
              <Text>{firstName}, </Text>
              <Text style={{ ...styles.boldText, ...styles.upperCase }}>
                {lastName}
              </Text>
            </View>
          </View>
          <View
            style={{
              ...styles.flexAlignCenter,
              ...styles.spaceBetween,
              ...styles.mb20,
            }}
          >
            <View style={{ ...styles.flexAlignCenter }}>
              <Image src="./assets/images/user.png" style={{ ...styles.iconSize }} />
              <Text style={styles.ml12}>Gender</Text>
            </View>
            <Text style={{ ...styles.boldText, ...styles.upperCase }}>{gender}</Text>
          </View>
          <View
            style={{
              ...styles.flexAlignCenter,
              ...styles.spaceBetween,
              ...styles.mb20,
            }}
          >
            <View style={{ ...styles.flexAlignCenter }}>
              <Image
                src="./assets/images/email.png"
                style={{ ...styles.iconSize }}
              />
              <Text style={styles.ml12}>Email Address</Text>
            </View>
            <Text>{email}</Text>
          </View>
          <View
            style={{
              ...styles.flexAlignCenter,
              ...styles.spaceBetween,
              ...styles.mb20,
            }}
          >
            <View style={{ ...styles.flexAlignCenter }}>
              <Image
                src="./assets/images/phone.png"
                style={{ ...styles.iconSize }}
              />
              <Text style={styles.ml12}>Phone Number</Text>
            </View>
            <Text>{phoneNumber}</Text>
          </View>
          <View
            style={{
              ...styles.flexAlignCenter,
              ...styles.spaceBetween,
              ...styles.mb20,
            }}
          >
            <View style={{ ...styles.flexAlignCenter }}>
              <Image src="./assets/images/mode.png" style={{ ...styles.iconSize }} />
              <Text style={styles.ml12}>Mode</Text>
            </View>
            <Text>{appointmentMode}</Text>
          </View>
          <View
            style={{
              ...styles.flexAlignCenter,
              ...styles.spaceBetween,
              ...styles.mb20,
            }}
          >
            <View style={{ ...styles.flexAlignCenter }}>
              <Image src="./assets/images/date.png" style={{ ...styles.iconSize }} />
              <Text style={styles.ml12}>Date</Text>
            </View>
            <Text>{`${moment(appointmentDate).format("DD/MM/YY")}`}</Text>
          </View>
          <View
            style={{
              ...styles.flexAlignCenter,
              ...styles.spaceBetween,
              ...styles.mb20,
            }}
          >
            <View style={{ ...styles.flexAlignCenter }}>
              <Image src="./assets/images/time.png" style={{ ...styles.iconSize }} />
              <Text style={styles.ml12}>Time</Text>
            </View>
            <View style={{ ...styles.flexAlignCenter }}>
              <Text>{formatTime(time)} </Text>
              <Text style={{ ...styles.boldText, ...styles.upperCase }}>WAT</Text>
            </View>
          </View>
          <View
            style={{
              ...styles.flexAlignCenter,
              ...styles.spaceBetween,
              ...styles.mb20,
            }}
          >
            <View style={{ ...styles.flexAlignCenter }}>
              <Image src="./assets/images/dob.png" style={{ ...styles.iconSize }} />
              <Text style={styles.ml12}>Date of birth</Text>
            </View>
            <Text>{`${moment(dateOfBirth).format("DD/MM/YY")}`}</Text>
          </View>
          <View
            style={{
              ...styles.flexAlignCenter,
              ...styles.spaceBetween,
              ...styles.mb20,
            }}
          >
            <View style={{ ...styles.flexAlignCenter }}>
              <Image src="./assets/images/aoc.png" style={{ ...styles.iconSize }} />
              <Text style={styles.ml12}>Area of Consultation</Text>
            </View>
            <Text>{name}</Text>
          </View>
          {regNum && (
            <View
              style={{
                ...styles.flexAlignCenter,
                ...styles.spaceBetween,
                ...styles.mb20,
              }}
            >
              <View style={{ ...styles.flexAlignCenter }}>
                <Image
                  src="./assets/images/emr.png"
                  style={{ ...styles.iconSize }}
                />
                <Text style={styles.ml12}>Hospital Reg/EMR Number</Text>
              </View>
              <Text>{regNum}</Text>
            </View>
          )}
          {preferredDoctor.id && (
            <View
              style={{
                ...styles.flexAlignCenter,
                ...styles.spaceBetween,
                ...styles.mb20,
              }}
            >
              <View style={{ ...styles.flexAlignCenter }}>
                <Image
                  src="./assets/images/with.png"
                  style={{ ...styles.iconSize }}
                />
                <Text style={styles.ml12}>Meeting With</Text>
              </View>
              <Text>{preferredDoctor.name}</Text>
            </View>
          )}
          <View
            style={{
              ...styles.flexAlignCenter,
              ...styles.spaceBetween,
            }}
          >
            <View style={{ ...styles.flexAlignCenter }}>
              <Image
                src="./assets/images/reason.png"
                style={{ ...styles.iconSize }}
              />
              <Text style={styles.ml12}>Reason for Visit</Text>
            </View>
            <Text style={{ textTransform: "capitalize" }}>
              {reasonForVisit?.reason}
            </Text>
          </View>
          {detail && (
            <View
              style={{
                ...styles.flexAlignTop,
                ...styles.spaceBetween,
                paddingRight: "50px",
              }}
            >
              <View style={{ ...styles.flexAlignCenter, marginRight: "10px" }}>
                <Image
                  src="./assets/images/details.png"
                  style={{ ...styles.iconSize }}
                />
                <Text style={styles.ml12}>Details</Text>
              </View>
              <Text>{detail}</Text>
            </View>
          )}
          <View
            style={{
              ...styles.flexAlignCenter,
              justifyContent: "center",
            }}
          >
            <Image style={{ height: "150px", width: "150px" }} src={canvasUrl} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default BookingSlip;
