import axios, { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import useSWR from "swr";

import BootstrapContext, { BootstrapContextInterface } from ".";
import theme, { appTheme } from "../../theme/theme";

export type BootstrapDataType = {
  data: {
    accessToken: string;
    color: string;
    id: string;
    logo: {
      url: string;
    };
    mode: string[];
    openTime: string;
    breakTime: any[];
    closeTime: string;
    breakInterval: string;
    status: boolean;
    name: string; // Todo: change it to the prop the backend returns
  };
};

const BootstrapProvider = ({ children }: { children: React.ReactNode }) => {
  const { accesstoken } = useParams();

  const fetcher = async (): Promise<BootstrapDataType> => {
    const url = `${process.env.REACT_APP_API_ENDPOINT_URL}calendar/settings?access_token=${accesstoken}`;
    const response = await axios.get(url);
    return response.data;
  };

  const { data, error } = useSWR(accesstoken ? "bootstrap" : null, fetcher);

  const sharedValues: BootstrapContextInterface = {
    loading: !data,
    accessToken: accesstoken,
    bootstrapData: data as BootstrapDataType,
    error: error as AxiosError,
    theme: data ? appTheme(data) : theme,
  };

  return (
    <BootstrapContext.Provider value={sharedValues}>
      {children}
    </BootstrapContext.Provider>
  );
};

export default BootstrapProvider;
