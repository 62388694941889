import { useState, useRef, useEffect } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";
import { parsePhoneNumber } from "react-phone-number-input";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import AppButton from "../../components/app-button/app-button";
import Popup from "./confirm-popup";
import ConfirmDetails from "./confirm-details";
import WizardFormHeading from "../../components/wizard-form-heading/wizard-form-heading";
import { getSessionStorage, setItem } from "../../helper";
import axios, { AxiosError } from "axios";
import { appointmentUrl } from "../../services/urls";
import { Input } from "../details-form/details-form";
import { useGlobalBootstrapContext } from "../../context/bootstrap";
import { SelectedAreaType } from "../consultation/options";

interface PropType {
  setCurrent: (current: number) => void;
  current: number;
}

interface CaptchType {
  token: string | null;
  expired: boolean;
  errorMessage: string;
}

const Confirm = ({ setCurrent, current }: PropType) => {
  const meetingId: string = getSessionStorage("meetingId");
  const { accessToken } = useGlobalBootstrapContext();

  const reCaptchaRef: any = useRef(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disableCaptcha, setDisableCaptcha] = useState(false);

  const [captcha, setCaptcha] = useState<CaptchType>({
    token: null,
    expired: true,
    errorMessage: "",
  });

  const url = window.location.href;

  useEffect(() => {
    if (url.startsWith(process.env.REACT_APP_TEST_URL as string)) {
      setDisableCaptcha(true);
      setBtnDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!getSessionStorage("firstLoad")) {
      setItem("firstLoad", true);
      reCaptchaRef.current?.reset();
    }
  }, []);

  const onFinishRecaptcha = (value: string | null) => {
    setBtnDisabled(false);
    setCaptcha({
      ...captcha,
      token: value,
      expired: value ? false : true,
      errorMessage: "",
    });
  };

  const handleCompleteBooking = async () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      reasonId,
      gender,
      detail,
      dateOfBirth,
      regNum,
    } = getSessionStorage("patientDetails") as Input;
    const {
      slot: { id },
      appointmentMode,
      preferredLocation,
    } = getSessionStorage("preferredDoctorDateAndTime");
    const { id: serviceId }: SelectedAreaType =
      getSessionStorage("areaOfConsultation");

    if (!disableCaptcha && captcha.expired && !captcha.token) {
      return setCaptcha({
        ...captcha,
        errorMessage: "Please verify you are not a bot!",
      });
    }
    try {
      setLoading(true);
      const response = (
        await axios.post(appointmentUrl(accessToken!), {
          firstName,
          lastName,
          email,
          phone: phoneNumber,
          phoneCountry: parsePhoneNumber(phoneNumber)?.country,
          slotId: id,
          mode: appointmentMode,
          reasonId,
          serviceId,
          gender,
          dateOfBirth,
          ...(regNum && { regNum }),
          ...(detail && { detail }),
          ...(preferredLocation && { branchId: preferredLocation }),
        })
      ).data;
      setItem("bookingId", response?.data?.bookingId);
      setItem("meetingId", response?.data?.id);
      setPopupOpen(true);
      return response;
    } catch (error) {
      let msg;
      if (error instanceof AxiosError) msg = error?.response?.data?.message;
      else msg = String(error);
      toast(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
      sessionStorage.removeItem("firstLoad");
    }
  };

  return (
    <main>
      <WizardFormHeading heading={"Confirm Details"} />
      <Box
        maxWidth="1017px"
        width="100%"
        mx="auto"
        mt={{ base: "4.8rem", md: "7.6rem" }}
      >
        <ConfirmDetails />
        <Flex
          mt="8.2rem"
          mb="2.4rem"
          mr={{ base: "1.6rem", md: "6.4rem" }}
          flexDir="column"
          gap="3rem"
        >
          <Flex alignSelf="center" flexDir="column" justify="center" align="center">
            {!disableCaptcha && (
              <>
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={`${process.env.REACT_APP_CAPTCHA_SITEKEY}`}
                  onChange={onFinishRecaptcha}
                  onExpired={() => setBtnDisabled(true)}
                />
                {captcha.errorMessage && (
                  <Text fontSize="1.2rem" color="red">
                    {captcha.errorMessage}
                  </Text>
                )}
              </>
            )}
          </Flex>
          <Flex gap="1rem" alignSelf="end">
            <AppButton
              variant="secondary"
              onClick={() => {
                sessionStorage.removeItem("firstLoad");
                setCurrent(current && current - 1);
              }}
            >
              Back
            </AppButton>
            <AppButton
              variant="primary"
              onClick={handleCompleteBooking}
              disabled={btnDisabled}
              loading={loading}
            >
              Complete Booking
            </AppButton>
          </Flex>
        </Flex>
      </Box>
      {popupOpen && meetingId && (
        <Popup
          setPopupOpen={setPopupOpen}
          popupOpen={popupOpen}
          setCurrent={setCurrent}
        />
      )}
      <ToastContainer />
    </main>
  );
};

export default Confirm;
