import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Textarea,
  Flex,
  Text,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useRef } from "react";
import PhoneInput from "react-phone-number-input";
import { Controller } from "react-hook-form";

import "react-phone-number-input/style.css";
import "./app-input.css";

interface OptionType {
  value: string;
  label: string;
}
interface PropType {
  label?: string;
  placeholder?: string;
  options?: OptionType[];
  isSelect?: boolean;
  isRequired?: boolean;
  isTextArea?: boolean;
  isPhone?: boolean;
  id?: string;
  errorMessage?: string;
  type?: string;
  isFile?: boolean;
  files?: File[];
  isDate?: boolean;
  onSelectFiles?: Function;
  onRemoveFile?: Function;
  register?: any;
  control?: any;
  onChange?: Function;
  value?: string;
  maxDate?: string;
}

const generalStyle = {
  height: "4.1rem",
  backgroundColor: "#F9F9FB",
  border: "0.4px solid rgba(15, 99, 255, 0.08)",
  borderRadius: "4px",
  color: "#000",
  fontSize: "1.4rem",
  fontWeight: "500",
};

const focusStyle = {
  outlineColor: "rgba(210, 205, 205, .8)",
  outlineStyle: "solid",
  outlineWidth: "1px",
  border: "none",
};

const AppInput = ({
  label,
  isSelect,
  placeholder,
  options,
  isRequired,
  isPhone,
  isTextArea,
  id,
  errorMessage,
  type,
  isFile,
  files,
  onSelectFiles,
  onRemoveFile,
  register,
  control,
  onChange,
  value,
  isDate,
  maxDate,
}: PropType) => {
  const fileInput: any = useRef(null);
  return (
    <FormControl isRequired={isRequired && isRequired}>
      <FormLabel htmlFor={id} fontSize="1.4rem">
        {label}
      </FormLabel>
      {isSelect ? (
        <Select
          id={id}
          {...register}
          required={isRequired && isRequired}
          placeholder={placeholder}
          style={{ ...generalStyle }}
          _focus={{ ...focusStyle }}
          value={value}
          onChange={onChange && onChange}
        >
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ) : isTextArea ? (
        <Textarea
          _focus={{ ...focusStyle }}
          style={{ ...generalStyle }}
          id={id}
          {...register}
          placeholder={placeholder}
        />
      ) : isPhone ? (
        <Controller
          name={id ? id : ""}
          control={control}
          render={({ field: { onChange: onPhoneChange, value: phoneValue } }) => (
            <>
              <PhoneInput
                id={id}
                onChange={onPhoneChange}
                value={phoneValue}
                defaultCountry="NG"
                _focus={{ ...focusStyle }}
                style={{ ...generalStyle }}
              />
            </>
          )}
        />
      ) : isFile ? (
        <>
          <Input
            onChange={(e: React.FormEvent<EventTarget>) => {
              const target = e.target as HTMLInputElement;
              onSelectFiles && onSelectFiles(target.files);
            }}
            ref={fileInput}
            display="none"
            type="file"
            id={id}
            {...register}
            multiple
          />
          <Flex
            align="center"
            justify="center"
            style={{ ...generalStyle }}
            borderStyle="dashed !important"
            height="9rem !important"
            cursor="pointer"
            onClick={() => fileInput.current.click()}
            mb="2rem"
          >
            {placeholder}
          </Flex>
          <Flex flexWrap="wrap" gap="1.5rem">
            {files &&
              files?.map((file: File) => (
                <Flex
                  fontWeight="600"
                  gap="1.5rem"
                  align="center"
                  justify="center"
                  fontSize="1.2rem"
                  color="#047168"
                  background="rgba(208, 254, 213, 0.3)"
                  borderRadius="3.6rem"
                  lineHeight="2.9rem"
                  height="2.9rem"
                  px="2.4rem"
                  key={file.name}
                >
                  <Text>{file.name}</Text>
                  <CloseIcon
                    onClick={() => onRemoveFile && onRemoveFile(file)}
                    cursor="pointer"
                    color="brand.main"
                  />
                </Flex>
              ))}
          </Flex>
        </>
      ) : isDate ? (
        <Input
          _focus={{ ...focusStyle }}
          style={{ ...generalStyle }}
          type="date"
          max={maxDate}
          id={id}
          {...register}
          placeContent="Select Date of Birth"
        />
      ) : (
        <Input
          _focus={{ ...focusStyle }}
          style={{ ...generalStyle }}
          id={id}
          {...register}
          placeholder={placeholder}
          type={type ? type : "text"}
        />
      )}
      {errorMessage && (
        <FormHelperText fontSize="1.2rem" color="red">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AppInput;
