import { Flex, Heading, Text, Button } from "@chakra-ui/react";

type PropType = {
  color?: string;
};

export default function ErrorDisplay({ color = "rgba(12, 170, 125, 1)" }: PropType) {
  return (
    <Flex
      flexDir="column"
      align="center"
      h="100vh"
      justify="center"
      textAlign="center"
      py="2.4rem"
      px="2.4rem"
    >
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient={`linear(to-r, teal.400, ${color})`}
        backgroundClip="text"
      >
        Oops!
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Something went wrong.
      </Text>
      <Text color={"gray.500"} mb={6}>
        Kindly refresh the page or contact your service provider.
      </Text>

      <Button
        colorScheme="teal"
        bgGradient={`linear(to-r, teal.400, teal.500, ${color})`}
        color="white"
        variant="solid"
        onClick={() => window.history.back()}
      >
        Go Back
      </Button>
    </Flex>
  );
}
