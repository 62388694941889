import { Box, Flex, Text, useTheme } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { PreferredDoctorDateAndTimeType } from "./date-time";

import { ReactComponent as Users } from "../../assets/icons/users.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as VideoIcon } from "../../assets/icons/video.svg";
import { ReactComponent as ChatIcon } from "../../assets/icons/chat.svg";

interface AppointmentModeProps {
  mode: string;
  setPreferredDoctorDateAndTime: (
    bookingDetails: PreferredDoctorDateAndTimeType
  ) => void;
  preferredDoctorDateAndTime: PreferredDoctorDateAndTimeType;
}

enum AppointmentModes {
  InPerson = "InPerson",
  Phone = "Phone",
  Chat = "Chat",
  Video = "Video",
}

const renderMode = (appointmentMode: string) => {
  if (appointmentMode === AppointmentModes.InPerson) return <Users />;
  if (appointmentMode === AppointmentModes.Phone) return <PhoneIcon />;
  if (appointmentMode === AppointmentModes.Video) return <VideoIcon />;
  if (appointmentMode === AppointmentModes.Chat) return <ChatIcon />;
};

const AppointmentMode: FunctionComponent<AppointmentModeProps> = ({
  mode,
  preferredDoctorDateAndTime,
  setPreferredDoctorDateAndTime,
}) => {
  const theme = useTheme();

  return (
    <Flex
      cursor="pointer"
      border={
        mode === preferredDoctorDateAndTime.appointmentMode
          ? `1px solid ${theme.colors.brand.main}`
          : "none"
      }
      key={mode}
      borderRadius=".4rem"
      py="2rem"
      align="center"
      gap="1.5rem"
      background="#F9F9FB"
      width="16.5rem"
      justify="center"
      onClick={(): void => {
        const preferredDoctorDateAndTimeCopy = preferredDoctorDateAndTime;
        preferredDoctorDateAndTimeCopy.appointmentMode = mode;
        preferredDoctorDateAndTimeCopy.preferredLocation &&
          preferredDoctorDateAndTime.appointmentMode !== "In-person" &&
          delete preferredDoctorDateAndTimeCopy.preferredLocation;
        setPreferredDoctorDateAndTime({ ...preferredDoctorDateAndTimeCopy });
      }}
    >
      {renderMode(mode)}
      <Text fontSize="12px">{mode}</Text>
      {mode === preferredDoctorDateAndTime.appointmentMode ? (
        <Flex
          width="1.6rem"
          height="1.6rem"
          borderRadius="50%"
          border={`1.6px solid ${theme.colors.brand.main}`}
          align="center"
          justify="center"
        >
          <Box
            width="1rem"
            height="1rem"
            bgColor={`${theme.colors.brand.main}`}
            borderRadius="50%"
          />
        </Flex>
      ) : (
        <Box
          width="16px"
          height="16px"
          backgroundColor="rgba(158, 158, 158, 0.25)"
          border="0.8px solid rgba(153, 153, 153, 0.5)"
          borderRadius="50%"
        />
      )}
    </Flex>
  );
};

export default AppointmentMode;
