import { Box, useTheme } from "@chakra-ui/react";
import moment from "moment";
import { FunctionComponent } from "react";
import { PreferredDoctorDateAndTimeType } from "./date-time";

export interface Slot {
  name: string;
  id: string;
  slotDate: string;
  status: boolean;
}

interface TimeSlotProps {
  slot: Slot;
  setPreferredDoctorDateAndTime: (
    bookingDetails: PreferredDoctorDateAndTimeType
  ) => void;
  preferredDoctorDateAndTime: PreferredDoctorDateAndTimeType;
}

const TimeSlot: FunctionComponent<TimeSlotProps> = ({
  slot,
  preferredDoctorDateAndTime,
  setPreferredDoctorDateAndTime,
}) => {
  const {
    colors: {
      brand: { main },
      typography: { dynamicTextColor },
    },
  } = useTheme();
  const { id, slotDate, status, name } = slot;
  const slotTime = moment(slotDate).format("LT");
  return (
    <Box
      background={
        preferredDoctorDateAndTime.slot.id === id ? `${main}` : `${dynamicTextColor}`
      }
      width="90%"
      padding="1.6rem 3.2rem"
      mb="1rem"
      borderStyle="solid"
      borderWidth="0.5px"
      borderColor={preferredDoctorDateAndTime.slot.id === id ? `${main}` : "#A0A0A0"}
      borderRadius="4px"
      textAlign="center"
      color={
        preferredDoctorDateAndTime.slot.id === id ? `${dynamicTextColor}` : `${main}`
      }
      fontWeight="700"
      fontSize="1.4rem"
      opacity={!status ? "0.5" : "1"}
      cursor={!status ? "not-allowed" : "pointer"}
      onClick={() =>
        preferredDoctorDateAndTime.appointmentDate &&
        id &&
        setPreferredDoctorDateAndTime({
          ...preferredDoctorDateAndTime,
          slot: {
            id,
            name,
            time: slotTime,
          },
        })
      }
    >
      {slotTime}
    </Box>
  );
};

export default TimeSlot;
